import { ApplicationRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})

export class TranslateService {
    lang:string ='es' ;
	private langSubject = new BehaviorSubject<string>('es');
	lang$ = this.langSubject.asObservable();
    constructor( private appRef: ApplicationRef ) {
        this.lang =  localStorage.getItem('language');
        const lang = localStorage.getItem('language');
        if(!this.lang) {
            localStorage.setItem('language','es');
        }
		this.langSubject.next(lang);
    }
    get GetLang():string {
        if(localStorage.getItem('language') === 'es') {
			return 'es'
        }
        else {
			return 'en'
        }
    }
	transform(key: string): string {
		const translations = require(`../../../assets/languaje/${this.lang}.json`);
        const keys = key.split('.');
        let translation = translations;
        for (const k of keys) {
            if (translation && translation.hasOwnProperty(k)) {
                translation = translation[k];
            } else {
                return key;
            }
        }
        return translation;
    }
    changeLang() {
        const currentLang = localStorage.getItem('language');
        let lang = currentLang === 'es' ? 'en' : 'es';
        localStorage.setItem('language', lang);
        this.lang = lang;
		this.langSubject.next(lang);

    }

}
