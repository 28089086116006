import { Component, OnInit, Renderer2, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-page403',
	templateUrl: './page403.component.html',
	styleUrls: ['./page403.component.scss']
})
export class Page403Component {
	public router = inject( Router );
	public ts = inject( TranslateService );
	private renderer = inject(Renderer2);
	local;
	idUserType;
	tryToLogin(){
		this.router.navigateByUrl('auth/login')
	}
	ngOnInit(): void {
		this.hideZendeskWidget();
	}
	hideZendeskWidget(): void {
		this.renderer.setStyle(document.getElementById('launcher'), 'display', 'none');


		const zendeskIframe = document.querySelector('iframe[title="Ventana de mensajería"]') as HTMLIFrameElement;
		if (zendeskIframe) {
			zendeskIframe.style.display = 'none';
		}
	}
}
