import { Dialog } from '@angular/cdk/dialog';
import { Injectable, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';

@Injectable({
	providedIn: 'root'
})
export class EncryptDataService implements OnChanges {
	private static readonly KEY_STORAGE_KEY = 'encryptionKey';
	public static primaryKey: string;

	constructor(private router: Router, private dialog:Dialog) {
		if (!EncryptDataService.primaryKey) {
			this.retrieveOrGenerateKey();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!EncryptDataService.primaryKey) {
			this.retrieveOrGenerateKey();
		}
	}

	retrieveOrGenerateKey(): void {
		const storedKey = localStorage.getItem(EncryptDataService.KEY_STORAGE_KEY);

		if (storedKey) {
		EncryptDataService.primaryKey = storedKey;
		} else {
		this.generatePrimaryKey();
		localStorage.setItem(EncryptDataService.KEY_STORAGE_KEY, EncryptDataService.primaryKey);
		}
	}

	private generatePrimaryKey(): void {
		EncryptDataService.primaryKey = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex);
	}

	private isPrimaryKeyValid(): boolean {
		// Agregar lógica de validación aquí si es necesario
		// Por ejemplo, podrías verificar la longitud o el formato de la clave.
		return EncryptDataService.primaryKey && EncryptDataService.primaryKey.length === 32;
	}

	encryptData(data: string): string {
		if (!this.isPrimaryKeyValid()) {
			throw new Error('Clave primaria no válida');
		}

		const ciphertext = CryptoJS.AES.encrypt(data + EncryptDataService.primaryKey, EncryptDataService.primaryKey).toString();
		return ciphertext;
	}

	decryptData(ciphertext: string): string {
		try {
			if (!this.isPrimaryKeyValid()) {
				throw new Error('Clave primaria no válida');
			}

			const bytes = CryptoJS.AES.decrypt(ciphertext, EncryptDataService.primaryKey);
			const originalText = bytes.toString(CryptoJS.enc.Utf8);
			const data = originalText.slice(0, -EncryptDataService.primaryKey.length);

			return data;
		} catch (error:any) {
			this.dialog.closeAll();
			localStorage.removeItem('data');
			localStorage.removeItem('encryptionKey');
			throw new Error('Error al desencriptar: ' + error.message);
		}
	}
}
