//Angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { CreateTicketsComponent } from './shared/components/create-tickets/create-tickets.component';
import { AuthGuard } from './core/guards/auth.guard';
import { Page403Component } from './shared/pages/page403/page403.component';
import { Page404Component } from './shared/pages/page404/page404.component';
import { Page500Component } from './shared/pages/page500/page500.component';
import { Page503Component } from './shared/pages/page503/page503.component';
//Guards

const options: ExtraOptions = {
	onSameUrlNavigation: 'reload',
};

const routes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		children: []
	},
	{
		path: 'auth', //Login
		loadChildren: () =>	import('./modules/auth/auth.module').then((m) => m.AuthModule)
	},
	{
		path: 'admin', //Dashboard-client
		loadChildren: () => import('./modules/admin-client/admin-client.module').then((m) => m.AdminClientModule),
	},
	{
		path: 'monitoreo', //Dashboard-client
		loadChildren: () => import('./modules/admin-client/admin-client.module').then((m) => m.AdminClientModule),
	},
	{
		path: 'carrier', //Dashboard-carrier
		loadChildren: () =>	import('./modules/admin-client/admin-client.module').then((m) => m.AdminClientModule),
	},
	{
		path:'create-tickets/:tokenTicket',
		component: CreateTicketsComponent
	},
	{
		path:'403',
		component:Page403Component
	},
    {
        path:'404',
        component:Page404Component
    },
	{
        path:'500',
        component:Page500Component
    },
	{
        path:'503',
        component:Page503Component
    },
	{
		path: '**',
		redirectTo: '403'
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	declarations: [],
})
export class AppRoutingModule {}
