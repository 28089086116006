import { Component, OnInit, Renderer2, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { EncryptDataService } from 'src/app/core/security/encrypt-data.service';

@Component({
  selector: 'app-page500',
  templateUrl: './page500.component.html',
  styleUrls: ['./page500.component.scss']
})
export class Page500Component implements OnInit{
	public router = inject( Router );
	public ts = inject( TranslateService );
	public location = inject( Location );
	public securityLocalStorage = inject( EncryptDataService );
	private renderer = inject(Renderer2);
	local;
	idUserType;
	ngOnInit(): void {
		this.local = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')))
		this.idUserType = this.local.result.id_user_type;
		this.hideZendeskWidget();
	}
	continueLocker(){
		if(this.idUserType === 6){
			this.router.navigateByUrl('warehouse/locker');
		}
		else if(this.idUserType === 7){
			this.router.navigateByUrl('vivipost/locker');
		}
		else if(this.idUserType === 9){
			this.router.navigateByUrl('admin/locker');
		}
		else if(this.idUserType === 10){
			this.router.navigateByUrl('monitoreo/locker');
		}
	}
	continueOrders(){
		if(this.idUserType === 6){
			this.router.navigateByUrl('warehouse/orders');
		}
		else if(this.idUserType === 7){
			this.router.navigateByUrl('vivipost/orders');
		}
		else if(this.idUserType === 9){
			this.router.navigateByUrl('admin/orders');
		}
		else if(this.idUserType === 10){
			this.router.navigateByUrl('monitoreo/orders');
		}
	}
	continueAccount(){
		if(this.idUserType === 6){
			this.router.navigateByUrl('warehouse/account-settings');
		}
		else if(this.idUserType === 7){
			this.router.navigateByUrl('vivipost/account-settings');
		}
		else if(this.idUserType === 9){
			this.router.navigateByUrl('admin/account-settings');
		}
		else if(this.idUserType === 10){
			this.router.navigateByUrl('monitoreo/account-settings');
		}
	}
	goBack(): void {
		this.location.back();
	}
	hideZendeskWidget(): void {
		this.renderer.setStyle(document.getElementById('launcher'), 'display', 'none');


		const zendeskIframe = document.querySelector('iframe[title="Ventana de mensajería"]') as HTMLIFrameElement;
		if (zendeskIframe) {
			zendeskIframe.style.display = 'none';
		}
	}
}
