import { Component, HostListener, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Interceptor } from './core/interceptors/interceptor.service';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnChanges {
	//title = 'DASHBOARD';
	language: string = 'es';
	mode: string = localStorage.getItem('mode') || 'light';

	id: number;
	lat: number;
	lon: number;
	version: string = 'v1.1.0.0';
	time;

	modeChange(mode: string): void {
		this.mode = mode;
		document.body.classList.toggle('dark-mode', this.mode === 'dark');
		localStorage.setItem('mode', mode);
	}

	constructor(private translate: TranslateService, private router: Router, private interceptor: Interceptor, private datePipe: DatePipe) {
		if ( localStorage.getItem('language') && localStorage.getItem('language') != '' && localStorage.getItem('language') != 'undefine') {
			this.language = localStorage.getItem('language');
		}
		translate.setDefaultLang(this.language.toLowerCase());
		localStorage.setItem('version', this.version);
		this.doSomething(this);
		if (this.mode === 'dark') {
			document.body.classList.add('dark-mode');
		}
	}

	// OnInit
	ngOnInit() {
		this.mode = localStorage.getItem('mode') || 'light';
		document.body.classList.toggle('dark-mode', this.mode === 'dark');
	}
	ngOnChanges(changes: SimpleChanges): void {
	}

	setId(id) {
		this.id = id;
	}
	getId() {
		return this.id;
	}

	move() {
		clearTimeout(this.time);
		this.time = setTimeout(() => {
			this.navigate();
		}, 600000);
	}

	navigate() {
		localStorage.removeItem('data');
		this.router.navigateByUrl('login');
	}

	@HostListener('window:beforeunload', ['$event'])
	public doSomething($event) {
		if (this.interceptor.getToken()) {
			this.disconectVideoCam();
		}
	}

	async disconectVideoCam() {
		const data = {
			language: localStorage.getItem('language'),
		};
		//TODO:DESCOMENTAR Y ARREGLAR
		//  await this.adminService.disconectVideoCam(data);
	}
}
