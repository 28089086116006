import { HttpClient } from '@angular/common/http';
import { Component, Input, inject, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-icon',
    template: '<span style="display: flex;" [innerHTML]="svgIcon"></span>',
})
export class IconComponent implements OnChanges {
    public httpClient = inject(HttpClient);
    public sanitizer = inject(DomSanitizer);

    @Input() name!: string;
    @Input() color!: string;
    @Input() size!: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl';
    @Input() strokeWidth: 'thin' | 'regular' | 'medium' | 'semibold' | 'bold' | 'extrabold' = 'medium';
    @Input() fillColor: string | undefined;

    public svgIcon: any;

    ngOnChanges(): void {
        if (!this.name) {
            this.svgIcon = '';
            return;
        }
        this.httpClient
            .get(`../../../../assets/icons/icons.json`, {
                responseType: 'text',
            })
            .subscribe((value: any) => {
                // Get SVG code
                const rawSvg = JSON.parse(value)[this.name.replace('dotted-', '')];

                // Change size
                let processedSvg = rawSvg.toString().replace('<svg ', `<svg style="width: var(--size-icon-${this.size}); height: var(--size-icon-${this.size});" `);

                // Change color
                const color = this.color.startsWith('#') ? this.color : `var(--${this.color})`;
                if (this.name === 'system-tiendanube') {
                    processedSvg = processedSvg.toString().replace(/fill=".*?"/g, `fill="${color}" stroke-width="${this.getStrokeWidth()}"`);
                } else if (!this.name.startsWith('system-')) {
                    if (this.fillColor) {
                        const fillColor = this.fillColor.startsWith('#') ? this.fillColor : `var(--${this.fillColor})`;
                        processedSvg = processedSvg.toString().replace(/stroke=".*?"/g, `stroke="${fillColor}" fill="${fillColor}" stroke-width="${this.getStrokeWidth()}"`);
                    } else {
                        processedSvg = processedSvg.toString().replace(/stroke=".*?"/g, `stroke="${color}" stroke-width="${this.getStrokeWidth()}"`);
                    }
                }

                // Add dotted
                if (this.name.startsWith('dotted-')) {
                    processedSvg = processedSvg.replace('<path ', '<path stroke-dasharray="3 3"');
                }

                this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(processedSvg);
            });
    }

    getStrokeWidth() {
        if (this.strokeWidth === 'thin') {
            return '1.25';
        } else if (this.strokeWidth === 'regular') {
            return '2';
        } else if (this.strokeWidth === 'medium') {
            return '2.25';
        } else if (this.strokeWidth === 'semibold') {
            return '2.50';
        } else if (this.strokeWidth === 'bold') {
            return '2.75';
        } else if (this.strokeWidth === 'extrabold') {
            return '3';
        } else {
            return '1.25';
        }
    }
}
